import React, { useEffect, useState } from 'react';

function Contact() {
  const [contactData, setContactData] = useState(null);
  const domain = window.location.hostname;
  console.log(domain);

  useEffect(() => {
    fetch(`https://${domain}/api/contact`)
      .then(response => response.json())
      .then(data => setContactData(data))
      .catch(error => console.error('Error fetching contact data:', error));
  }, []);

  return (
    <div>
      <h1>Contact Us</h1>
      {contactData ? (
        <div>
          <p>{contactData.message}</p>
          <p>Email: {contactData.email}</p>
          <p>Phone: {contactData.phone}</p>
        </div>
      ) : (
        <p>Loading contact information...</p>
      )}
    </div>
  );
}

export default Contact;
