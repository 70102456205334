const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';
console.log('API_BASE_URL:', API_BASE_URL);
export const endpoints = {
    auth: {
        login: `${API_BASE_URL}/auth/login`,
        signup: `${API_BASE_URL}/auth/signup`,
    },
    contact: `${API_BASE_URL}/contact`,
};

export const apiRequest = async (endpoint, options = {}) => {
    try {
        console.log('Making request to:', endpoint);
        const response = await fetch(endpoint, {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                ...options.headers,
            },
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('API Request Error:', error);
        throw error;
    }
};
