import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Routes from './routes';

function App() {
  useEffect(() => {
    // Function to get client IP
    const getClientIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        console.log('A client connected - IP:', data.ip);
      } catch (error) {
        console.log('A client connected - IP: Unable to fetch');
      }
    };

    getClientIP();
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes />
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
