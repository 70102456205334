import posthog from 'posthog-js';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import '../App.css';

// Custom hook for typing animation
function useTypingEffect(text, typingSpeed = 150) {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        setDisplayedText(prev => prev + text.charAt(i));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, typingSpeed);

    return () => clearInterval(typingInterval);
  }, [text, typingSpeed]);

  return displayedText;
}

function Home() {
  const [showConfetti, setShowConfetti] = useState(false);
  const typedName = useTypingEffect(" Atharva Santosh Gadekar");

  useEffect(() => {
    setShowConfetti(true);
    const timer = setTimeout(() => setShowConfetti(false), 5000); // Confetti lasts for 5 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App-content">
      {showConfetti && <Confetti />}
      <h1>{typedName}</h1>
      <p className="subtitle">Software Engineer</p>
    </div>
  );
}

export default Home;
