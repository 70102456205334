import React from 'react';

function Footer() {
  const handleLinkClick = (linkName) => {
    // Assuming you're using posthog for analytics
    // posthog.capture('link_clicked', { link: linkName });
  };

  return (
    <footer className="App-footer">
      <div className="social-links">
        <a href="https://www.linkedin.com/in/atharvagadekar/" target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick('LinkedIn')}>
          LinkedIn
        </a>
        <a href="https://x.com/Atharva5416" target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick('X')}>
          X
        </a>
      </div>
    </footer>
  );
}

export default Footer;
